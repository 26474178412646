import { toMoney, toPhoneNumberNational } from '@/libs/app';
import { humanTimeDate } from '@/libs/dayjs';
import React from 'react';
import { ProductTypes, UserConversation } from '../../const';
import { PriceSymbol } from '@/layouts/shared';
import { TFunction } from 'next-i18next';
type RenderAuctionWinType = {
  userId: number;
  content: string;
  createdAt: string;
  idMessAd: number;
  ownerId: number;
  sender: UserConversation | undefined;
  product: ProductTypes;
  t: TFunction;
};
export const RenderAuctionWin = (props: RenderAuctionWinType) => {
  const { ownerId, userId, idMessAd, content, createdAt, sender, product, t } = props;
  return (
    <div
      className={`${ownerId === userId ? 'chat-user' : 'custom-chat-admin ca-rec tw-break-all'}  ${
        ownerId === userId && !idMessAd ? 'custom-chat-user cu-rec' : ''
      } tw-px-4 tw-py-1 tw-rounded-2xl tw-w-fit tw-relative tw-max-w-70 tw-break-all`}
    >
      <div className="tw-font-light tw-text-xs">Hệ thống</div>
      <div
        className="tw-mt-2"
        style={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
        }}
      >
        Chúc mừng khách hàng đã đấu giá thành công sản phẩm <b>{product.product_code}</b> với giá:{' '}
        <span className="tw-text-warning-90 tw-font-semibold">{toMoney(product.price, t, PriceSymbol.$)}</span>
        <br /> Vui lòng liên hệ nhân viên An Thư để hổ trợ tạo đơn hàng.
      </div>
      <div
        className={`tw-text-right ${ownerId === userId ? 'tw-text-white' : 'tw-text-secondary-50'}`}
        style={{
          fontSize: '9px',
        }}
      >
        {humanTimeDate(createdAt)}
      </div>
    </div>
  );
};
