import { ConversationQueryResponse, DirectConverSationResponse, GetConversationUsersPayload, GetConversationUsersResponse, RatingStaffPayload } from './const';
import {
  ProductQueryResponse,
  ConversationsQueryPayload,
  ConversationsQueryResponse,
  ConversationsSearchQueryPayload,
  FetchConversationMessagesPayload,
  ConversationMessagesQueryResponse,
  staffUserQueryResponse,
  RatingStaffResponse,
  FetchRatingStaffPayload,
  RatingStaffQueryResponse,
} from '@/features/consults/chat/const';
import { QueryKey } from '@/layouts/shared';
import { useQuery, QueryObserverOptions, MutationOptions, useMutation } from 'react-query';
import axios, { AxiosChatInstance, AxiosErrorResponse, AxiosResponse } from '@/libs/axios';
import { OrdersQueryDetailResponse } from '@/features/orders/list/const';
import * as qs from 'qs';
export const fetchConversationMessages = (id: number, params: string | number | FetchConversationMessagesPayload) => {
  return AxiosChatInstance.get(`/v2/me/chat/conversations/${id}/messages`, { params });
};
export const fetchConversationServer = (id: number, token: string | undefined) => {
  return AxiosChatInstance.get(`/v2/me/chat/conversations/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const uploadImage = (payload: FormData) => {
  return axios.post('/api/v1/images/chat/upload', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const sendChatOrder = (payload: any) => {
  return AxiosChatInstance.post(`/v2/me/chat/conversations/${payload.id}/messages`, payload.data);
};
export const fetchConversation = (id: number) => {
  return AxiosChatInstance.get(`/v2/me/chat/conversations/${id}`);
};

export const fetchConversationUsers = (conversationId: number, params: GetConversationUsersPayload) => {
  return AxiosChatInstance.get(`/v2/me/chat/conversations/${conversationId}/get-users`, { params });
};

export const createDirectConversation = () => {
  return AxiosChatInstance.post(`/v2/me/chat/conversations/direct`);
};
export const fetchProduct = (id: number) => {
  return axios.get(`/api/v1/products/${id}`);
};
export const fetchProductByChat = (id: number) => {
  return axios.get(`/api/v1/products/${id}/chat`);
};
export const fetchRatingChat = ({ pId, staffId }: FetchRatingStaffPayload) => {
  return axios.get(`/api/v1/rating/${pId}/${staffId}`);
};

export const ratingStaff = (payload: RatingStaffPayload) => {
  return axios.post(`/api/v1/rating`, payload);
};

export const fetchConversations = (params: string | ConversationsQueryPayload) => {
  let p = qs.stringify(params, { indices: false });
  return AxiosChatInstance.get(`/v2/me/chat/conversations?${p}`);
};
export const seenConversations = (id: number) => {
  return AxiosChatInstance.put(`/v2/me/chat/conversations/${id}/seen`);
};
export const searchConversations = (params: ConversationsSearchQueryPayload) => {
  return AxiosChatInstance.get(`/v2/me/chat/conversations/search`, { params });
};
export const fetchStaffProfileById = (id: number) => {
  return axios.get(`/api/v1/user/staff/profile/${id}`);
};
export const getLastedDirectConversation = () => {
  return AxiosChatInstance.get(`/v2/me/chat/conversations/direct/latest`);
};
export const fetchOrderDetailByCode = (code: string) => {
  return axios.get(`/api/v1/order/${code}`);
};
export const fetchProductsByCodes = (params: { product_code: string }) => {
  return axios.get(`/api/v1/products`, { params });
};
export const changeAliasName = (payload: { alias_name: string }) => {
  return AxiosChatInstance.post(`/v2/me/chat/conversations/change-alias-name`, payload);
};
export const updateNotification = (conversationId: number, payload: { muted_notification: boolean }) => {
  return AxiosChatInstance.post(`/v2/me/chat/conversations/group/${conversationId}/update-notification`, payload);
};
export const joinGroupChat = (conversationId: number) => {
  return AxiosChatInstance.post(`/v2/me/chat/conversations/group/${conversationId}/join`);
};
export const leaveGroupChat = (conversationId: number) => {
  return AxiosChatInstance.post(`/v2/me/chat/conversations/group/${conversationId}/leave`);
};

export const useFetchConversationMessagesQuery = (
  id: number,
  payload: FetchConversationMessagesPayload,
  options?: QueryObserverOptions<
    AxiosResponse<ConversationMessagesQueryResponse>,
    AxiosErrorResponse,
    ConversationMessagesQueryResponse,
    AxiosResponse<ConversationMessagesQueryResponse>,
    (string | number | FetchConversationMessagesPayload)[]
  >
) => {
  return useQuery(
    [QueryKey.ConversationMessages, id, payload],
    ({ queryKey }) => {
      const [, , params] = queryKey;

      return fetchConversationMessages(id, params);
    },
    options
  );
};
export const useFetchStaffProfileById = (
  id: number,
  options?: QueryObserverOptions<
    AxiosResponse<staffUserQueryResponse>,
    AxiosErrorResponse,
    staffUserQueryResponse,
    AxiosResponse<staffUserQueryResponse>,
    (number | string)[]
  >
) => {
  return useQuery([QueryKey.Users, id], () => fetchStaffProfileById(id), options);
};
export const useFetchLastedDirectConversation = (
  options?: QueryObserverOptions<AxiosResponse<any>, AxiosErrorResponse, any, AxiosResponse<any>, string[]>
) => {
  return useQuery([QueryKey.Conversations], () => getLastedDirectConversation(), options);
};

export const useFetchOrderDetailByCode = (
  code: string,
  options?: QueryObserverOptions<
    AxiosResponse<OrdersQueryDetailResponse>,
    AxiosErrorResponse,
    OrdersQueryDetailResponse,
    AxiosResponse<OrdersQueryDetailResponse>,
    (string | string)[]
  >
) => {
  return useQuery([QueryKey.OrderDetail, code], () => fetchOrderDetailByCode(code), options);
};

export const useFetchConversationsQuery = (
  payload: ConversationsQueryPayload,
  options?: QueryObserverOptions<
    AxiosResponse<ConversationsQueryResponse>,
    AxiosErrorResponse,
    ConversationsQueryResponse,
    AxiosResponse<ConversationsQueryResponse>,
    (string | ConversationsQueryPayload)[]
  >
) => {
  return useQuery(
    [QueryKey.ConversationPopup, payload],
    ({ queryKey }) => {
      const [, params] = queryKey;

      return fetchConversations(params);
    },
    options
  );
};
export const useFetchConversationById = (
  id: number,
  options?: QueryObserverOptions<
    AxiosResponse<ConversationQueryResponse>,
    AxiosErrorResponse,
    ConversationQueryResponse,
    AxiosResponse<ConversationQueryResponse>,
    (string | number)[]
  >
) => {
  return useQuery(
    [QueryKey.Conversation, id],
    () => {
      return fetchConversation(id);
    },
    options
  );
};
export const useUploadImage = (
  options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, FormData, unknown>
) => {
  return useMutation((payload: FormData) => uploadImage(payload), options);
};
export const useCreateOrderChat = (options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, any, unknown>) => {
  return useMutation((payload: any) => sendChatOrder(payload), options);
};
export const useRatingStaff = (
  options?: MutationOptions<AxiosResponse<RatingStaffResponse>, AxiosErrorResponse, RatingStaffPayload, unknown>
) => {
  return useMutation((payload: RatingStaffPayload) => ratingStaff(payload), options);
};

export const useSeenConversation = (
  options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, any, unknown>
) => {
  return useMutation((id: number) => seenConversations(id), options);
};
export const useFetchProductQuery = (
  id: number,
  options?: QueryObserverOptions<
    AxiosResponse<ProductQueryResponse>,
    AxiosErrorResponse,
    ProductQueryResponse,
    AxiosResponse<ProductQueryResponse>,
    (number | string)[]
  >
) => {
  return useQuery([QueryKey.Products, id], () => fetchProduct(id), options);
};
export const useFetchProductByChatQuery = (
  id: number,
  options?: QueryObserverOptions<
    AxiosResponse<ProductQueryResponse>,
    AxiosErrorResponse,
    ProductQueryResponse,
    AxiosResponse<ProductQueryResponse>,
    (number | string)[]
  >
) => {
  return useQuery([QueryKey.ProductChat, id], () => fetchProductByChat(id), options);
};
export const useFetchRatingStaffQuery = (
  payload: FetchRatingStaffPayload,
  options?: QueryObserverOptions<
    AxiosResponse<RatingStaffQueryResponse>,
    AxiosErrorResponse,
    RatingStaffQueryResponse,
    AxiosResponse<RatingStaffQueryResponse>,
    (FetchRatingStaffPayload | string)[]
  >
) => {
  return useQuery([QueryKey.RatingStaff, payload], () => fetchRatingChat(payload), options);
};

export const useSearchConversationsQuery = (
  payload: ConversationsSearchQueryPayload,
  options?: QueryObserverOptions<
    AxiosResponse<ConversationsQueryResponse>,
    AxiosErrorResponse,
    ConversationsQueryResponse,
    AxiosResponse<ConversationsQueryResponse>,
    (string | ConversationsSearchQueryPayload)[]
  >
) => {
  return useQuery(
    [QueryKey.Conversations, payload],
    () => {
      const { keyword } = payload;

      let params = {} as ConversationsSearchQueryPayload;
      if (keyword) {
        params = { ...params, keyword };
      }

      return searchConversations(params);
    },
    options
  );
};

export const useCreateDirectConversation = (
  options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, any, unknown>
) => {
  return useMutation(() => createDirectConversation(), options);
};

export const useFetchConversationUsersQuery = (
  id: number,
  payload: GetConversationUsersPayload,
  options?: QueryObserverOptions<
    AxiosResponse<GetConversationUsersResponse>,
    AxiosErrorResponse,
    GetConversationUsersResponse,
    AxiosResponse<GetConversationUsersResponse>,
    (string | number | GetConversationUsersPayload)[]
  >
) => {
  return useQuery(
    [QueryKey.ConversationUsers, id, payload],
    () => {
      return fetchConversationUsers(id, payload);
    },
    options
  );
};

export const useJoinGroupChat = (options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, any, unknown>) => {
  return useMutation((id: number) => joinGroupChat(id), options);
};

export const useLeaveGroupChat = (options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, any, unknown>) => {
  return useMutation((id: number) => leaveGroupChat(id), options);
};

export const useChangeAliasName = (options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, any, unknown>) => {
  return useMutation((payload: { alias_name: string }) => changeAliasName(payload), options);
};

export const useUpdateNotification = (options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, any, unknown>) => {
  return useMutation((payload: { id: number, muted_notification: boolean }) => updateNotification(payload.id, { muted_notification: payload.muted_notification }), options);
};
