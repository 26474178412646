import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { fetchProductsByCodes } from '@/features/consults/chat/api';
import { UserConversation } from '../../const';
import { humanTimeDate } from '@/libs/dayjs';
import {
  AuctionStatus,
  DiscountStatus,
  PagePath,
  PriceSymbol,
} from '@/layouts/shared';
import { createHandle, toMoney } from '@/libs/app';
import {
  Link,
  Image,
  Button,
  Icon,
  AuctionIcon,
  WalletIcon,
} from '@/components';
import { TFunction } from 'next-i18next';
import AuctionModal from '@/features/livestream/detail/partials/chat/Comments/AuctionModal';

interface MessageProductDetailProps {
  product: any;
  userId: number;
  msgId: number;
  t: TFunction;
}

interface MessageProductsProps {
  userId: number;
  ownerId: number;
  ownerName: string;
  idMessAd: number;
  sender: UserConversation | undefined;
  createdAt: string;
  isPopup?: boolean;
  onError?: () => void;
  onSuccess?: () => void;
  msgId: number;
  productCodes: string[];
  t: TFunction;
}

const MessageProductDetail = ({
  product,
  userId,
  msgId,
  t,
}: MessageProductDetailProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpenModal = () => {
    setOpen(true);
  };

  const isDiscount =
    product.product_discount?.discount &&
    product.product_discount.status === DiscountStatus.Active;

  if (!product?.id) {
    return (
      <div className="tw-border tw-rounded-lg tw-p-2">
        Sản phẩm đang được chỉnh sửa vui lòng chờ mở bán lại
      </div>
    );
  }

  return (
    <>
      <div className="tw-border tw-rounded-lg tw-p-2">
        <div className="tw-flex tw-gap-x-2">
          <Link
            url={{
              pathname: PagePath.ProductDetailPage,
              query: {
                id: `${createHandle(product.product_name)}-${product?.id}-${
                  product?.product_code
                }`,
              },
            }}
          >
            <div
              className="tw-relative !tw-w-16 !tw-h-16 tw-bg-center tw-bg-no-repeat tw-bg-cover tw-rounded-5"
              style={{
                backgroundImage: `url('${product?.image}')`,
              }}
              title={product.product_name}
            >
              {isDiscount && (
                <>
                  <div className="tw-w-6 tw-h-6 tw-absolute tw-top-2 tw-right-2">
                    <Image
                      src="/sale.png"
                      className="tw-w-full tw-h-full"
                      alt="sale"
                    ></Image>
                  </div>
                </>
              )}
            </div>
          </Link>
          <div className="tw-flex-1 tw-flex tw-flex-col">
            <Link
              className="tw-w-full"
              url={{
                pathname: PagePath.ProductDetailPage,
                query: {
                  id: `${createHandle(product.product_name)}-${product?.id}-${
                    product?.product_code
                  }`,
                },
              }}
            >
              <h4 className="tw-text-primary-main tw-text-sm tw-font-semibold">
                {product?.product_name}
              </h4>
            </Link>
            <div className="tw-text-sm ">
              <span className="tw-text-secondary-70">
                {product?.product_code}
              </span>
            </div>
            {product.is_auction && product.auction ? (
              <div className="tw-text-sm">
                <i>
                  Giá khởi điểm:{' '}
                  <span className="tw-text-warning-90 tw-font-semibold">
                    {toMoney(
                      product.auction?.origin_price || 0,
                      t,
                      PriceSymbol.$
                    )}
                  </span>
                </i>
              </div>
            ) : (
              <div className="tw-text-sm">
                <span className="tw-text-warning-90 tw-font-semibold">
                  {product.product_discount?.discount &&
                  product.product_discount.status === DiscountStatus.Active
                    ? toMoney(
                        (product.price *
                          (100 - product.product_discount?.discount)) /
                          100,
                        t,
                        PriceSymbol.$
                      )
                    : toMoney(product.price, t, PriceSymbol.$)}
                </span>
                {' ~ '}
                <span className="tw-text-primary-main tw-font-semibold">
                  {product.product_discount?.discount &&
                  product.product_discount.status === DiscountStatus.Active
                    ? toMoney(
                        (product.token *
                          (100 - product.product_discount?.discount)) /
                          100,
                        t,
                        PriceSymbol.Atd
                      )
                    : toMoney(product.token, t, PriceSymbol.Atd)}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="tw-flex tw-justify-end">
          {product.is_auction && product.auction ? (
            <Button
              onClick={() => {
                handleOpenModal();
              }}
              disabled={
                product.auction?.status !== AuctionStatus.Started || !userId
              }
              className="tw-bg-warning-90 tw-text-white tw-uppercase tw-flex tw-items-center tw-gap-x-1 tw-border-warning-90 tw-text-xs tw-w-max tw-px-6 tw-py-1"
            >
              <Icon icon={<AuctionIcon />} />
              <span>Đấu giá</span>
            </Button>
          ) : (
            <Link
              url={{
                pathname: PagePath.ProductDepositPage,
                query: { id: product?.id, message_id: msgId },
              }}
              className="tw-bg-primary-main !tw-text-white tw-uppercase tw-flex tw-items-center tw-gap-x-1 tw-border-primary-main tw-text-xs tw-w-max tw-px-6 tw-py-1 tw-rounded-5"
            >
              <Icon
                icon={<WalletIcon />}
                className="tw-w-4 tw-h-4 tw-mr-3 tw-text-white"
              />
              Đặt cọc
            </Link>
          )}
        </div>
      </div>
      {open && product?.id && (
        <AuctionModal
          t={t}
          handleCloseModal={() => setOpen(false)}
          productCode={product.product_code}
          productName={product.product_name}
          productId={product.id}
        />
      )}
    </>
  );
};

const RenderProductsMessage = (props: MessageProductsProps) => {
  const {
    ownerId,
    ownerName,
    userId,
    idMessAd,
    sender,
    createdAt,
    onError,
    onSuccess,
    isPopup,
    productCodes,
    msgId,
    t,
  } = props;

  const [products, setProducts] = useState<any[]>([]);

  useEffect(() => {
    getProductInfo(productCodes);
  }, [productCodes]);

  const getProductInfo = async (productCodes: string[]) => {
    fetchProductsByCodes({
      product_code: productCodes.join(','),
    })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setProducts(res.data);
        }
      })
      .catch((err) => toast.error(err?.data?.message));
  };

  return (
    <div
      className={`${
        ownerId === userId
          ? 'chat-user custom-chat-user cu-rec'
          : 'custom-chat-admin ca-rec tw-break-all'
      }  ${ownerId === userId && !idMessAd ? 'custom-chat-user cu-rec' : ''} ${
        isPopup ? '' : 'tw-max-w-70'
      } tw-px-4 tw-pt-2 tw-pb-1 tw-rounded-2xl tw-w-fit tw-relative tw-break-all`}
    >
      {sender && ownerId !== userId && (
        <div
          className={`tw-text-secondary-30 ${!isPopup && 'tw-font-semibold'}`}
          style={{
            fontSize: isPopup ? '10px' : '15px',
            lineHeight: '15px',
          }}
        >
          {`An Thư Diamond - ${sender?.user_name}`}
        </div>
      )}
      {ownerId === userId && ownerName && (
        <div
          className={`tw-text-white ${!isPopup && 'tw-font-semibold'}`}
          style={{
            fontSize: isPopup ? '10px' : '15px',
            lineHeight: '15px',
          }}
        >
          {ownerName}
        </div>
      )}
      <div className="tw-flex tw-flex-col tw-gap-2 tw-py-2">
        {products.map((item: any, index: number) => (
          <MessageProductDetail
            key={`product-${index}`}
            product={item}
            userId={userId}
            msgId={msgId}
            t={t}
          />
        ))}
      </div>
      <div
        className={`tw-text-right ${
          ownerId === userId ? 'tw-text-white' : 'tw-text-secondary-50'
        }`}
        style={{
          fontSize: isPopup ? '11px' : '9px',
        }}
      >
        {humanTimeDate(createdAt)}
      </div>
    </div>
  );
};

export default RenderProductsMessage;
