type IconProps = {
  color?: string;
  width?: number;
  height?: number;
};

export const CommentWithHeartIcon = ({ color, width, height }: IconProps) => {
  const _w = width || 23;
  const _h = height || 23;

  return (
    <>
      <svg
        width={_w}
        height={_h}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.58268 17.3334C8.17316 18.1493 10.0028 18.3702 11.7418 17.9565C13.4808 17.5428 15.0148 16.5216 16.0675 15.0769C17.1201 13.6321 17.6222 11.859 17.4832 10.0768C17.3441 8.29472 16.5732 6.62085 15.3092 5.35687C14.0452 4.09289 12.3713 3.32192 10.5892 3.18289C8.80709 3.04386 7.03391 3.54591 5.5892 4.59858C4.14449 5.65124 3.12326 7.1853 2.70953 8.9243C2.29581 10.6633 2.5168 12.4929 3.33268 14.0834L1.66602 19L6.58268 17.3334Z"
          stroke={color || 'currentColor'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1672 8.33382C12.7778 7.95209 12.2542 7.73828 11.7089 7.73828C11.1635 7.73828 10.64 7.95209 10.2505 8.33382L10.0005 8.66715L9.70885 8.41715C9.51781 8.21037 9.28559 8.04588 9.02714 7.93427C8.76869 7.82265 8.48973 7.76639 8.20823 7.7691C7.92672 7.7718 7.64889 7.83342 7.39263 7.94998C7.13638 8.06654 6.90736 8.23546 6.72033 8.44587C6.5333 8.65628 6.39239 8.90352 6.30668 9.17168C6.22097 9.43983 6.19235 9.72297 6.22267 10.0029C6.25298 10.2827 6.34157 10.5532 6.48271 10.7968C6.62386 11.0403 6.81444 11.2517 7.04219 11.4171L10.0422 14.3338L13.0422 11.4171C14.0422 10.4171 13.9589 9.16715 13.2089 8.33382"
          stroke={color || 'currentColor'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
