import { ChatReducerType, ChatReducerTypes } from '@/features/consults/chat/const';

export const initialState = {
  messageMapper: {},
  groupUsers: [],
  isShowUpdateAliasName: false,
};

export type ChatActionType =
  | { type: ChatReducerType.OnChatSubmit; payload: ChatReducerTypes['messageMapper'] }
  | { type: ChatReducerType.OnMessageMapperInit; payload: ChatReducerTypes['messageMapper'] }
  | { type: ChatReducerType.SetConversationUsers; payload: ChatReducerTypes['groupUsers'] }
  | { type: ChatReducerType.SetShowUpdateAliasName; payload: boolean };

export const reducer = (state: ChatReducerTypes, action: ChatActionType): ChatReducerTypes => {
  switch (action.type) {
    case ChatReducerType.OnMessageMapperInit:
      return {
        ...state,
        messageMapper: {
          ...initialState.messageMapper,
          ...action.payload,
        },
      };

    case ChatReducerType.OnChatSubmit:
      return {
        ...state,
        messageMapper: {
          ...state.messageMapper,
          ...action.payload,
        },
      };

    case ChatReducerType.SetConversationUsers:
      return {
        ...state,
        groupUsers: action.payload,
      };

    case ChatReducerType.SetShowUpdateAliasName:
      return {
        ...state,
        isShowUpdateAliasName: action.payload,
      };

    default:
      return { ...initialState };
  }
};
