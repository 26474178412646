import React, { useContext } from 'react';
import ChatPopup from '@/features/consults/chatpopup';
import { WebSocketContext } from '@/contexts/websocket';
import { isMobile } from 'react-device-detect';
type IIsAuth = {
  isAuth: boolean;
  isMobile: boolean;
};
const ChatPopupPage = ({ isAuth, isMobile }: IIsAuth) => {
  const { socket: ws, reconnect } = useContext(WebSocketContext) || {};
  return <ChatPopup socket={ws} isMobile={isMobile} isAuth={isAuth} />;
};

export default ChatPopupPage;
