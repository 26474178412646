import { SocketChanel } from '@/layouts/shared';
import { getAuth } from '@/libs/cookies';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

type AppProviderProps = {
  children: ReactNode;
  pageProps: any;
};

type WebSocketContextType = {
  socket: WebSocket | null;
  reconnect: () => void;
};

export const WebSocketContext = createContext<WebSocketContextType | null>(
  null
);

const WebSocketProvider = ({ children, pageProps }: AppProviderProps) => {
  const [waitingToReconnect, setWaitingToReconnect] = useState<boolean | null>(
    null
  );
  const clientRef = useRef<any>(null);
  const timeOut = useRef<any>(null);
  const retryRef = useRef<any>(null);
  const reconnectRef = useRef<boolean>(false);
  const [socket, setSocket] = useState<any>();
  const { isAuth } = pageProps;

  const ping = (ws: any) => {
    let socketPayload = { method: 'ping' };
    ws.send(JSON.stringify(socketPayload));
  };

  const connectWebSocket = useCallback(() => {
    const { token } = getAuth();
    if (waitingToReconnect || clientRef.current) return;

    const ws = new WebSocket(
      `${
        process.env.WEBSOCKET_URL || 'wss://api-dev.anthu.tech/ws/realtime'
      }?token=${token}`
    );
    clientRef.current = ws;
    reconnectRef.current = false;
    console.log('ws connecting...');

    ws.onopen = () => {
      console.log('ws connected');
      const socketPayload = {
        method: 'subscribe',
        type: 'unicast',
        channels: [{ id: SocketChanel.Consult }],
      };
      ws.send(JSON.stringify(socketPayload));

      timeOut.current = setInterval(() => ping(ws), 15000);
    };

    setSocket(ws);

    ws.onclose = () => {
      if (clientRef.current) {
        console.log('ws closed by server');
      } else if (reconnectRef.current) {
        console.log('ws closed to reconnect');
        return;
      } else {
        console.log('ws closed by app component unmount');
        return;
      }
      console.log('ws closed');
      setWaitingToReconnect(true);
      retryRef.current = setTimeout(() => setWaitingToReconnect(null), 3000);
    };

    ws.onerror = (evt) => {
      console.error('WebSocket error:', evt);

      setWaitingToReconnect(true);
      retryRef.current = setTimeout(() => setWaitingToReconnect(null), 3000);
    };
  }, [waitingToReconnect]);

  const reconnect = () => {
    if (clientRef.current) {
      clientRef.current.close();
      clientRef.current = null;
    }
    reconnectRef.current = true;
    setWaitingToReconnect(null);
  };

  useEffect(() => {
    if (isAuth) {
      connectWebSocket();
    }

    return () => {
      console.log('close connection');
      clientRef.current?.close();
      clientRef.current = null;
      if (timeOut.current) clearInterval(timeOut.current);
      if (retryRef.current) clearTimeout(retryRef.current);
      timeOut.current = null;
      retryRef.current = null;
      setWaitingToReconnect(null);
    };
  }, [waitingToReconnect, isAuth, connectWebSocket]);

  return (
    <WebSocketContext.Provider value={{ socket, reconnect }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  return context;
};
