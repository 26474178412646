/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
import React, {
  useEffect,
  Dispatch,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';

import * as yup from 'yup';
import toast from 'react-hot-toast';

import {
  Message,
  ConversationPayloadDefault,
  ImageUploadType,
  User,
  ProductTypes,
  ChatReducerType,
  ChatReducerTypes,
  Conversation,
} from '@/features/consults/chat/const';
import { humanTimeDate } from '@/libs/dayjs';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChatActionType } from '@/features/consults/chat/reducer';
import {
  TextField,
  InputVariant,
  Icon,
  SendIcon,
  Button,
  ImageUploadIcon,
  LoadingDot32px,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonVariant,
  Image,
} from '@/components';
import {
  fetchRatingChat,
  useFetchConversationById,
  useFetchConversationMessagesQuery,
  useFetchProductByChatQuery,
  useRatingStaff,
  useSeenConversation,
  useUploadImage,
} from '@/features/consults/chat/api';
import { RenderImage } from '@/features/consults/chat/partials/message/RenderImage';
import { RenderOrder } from '@/features/consults/chatpopup/partials/RenderOrder';
import { MessageType } from '@/layouts/shared';
import { toPhoneNumberNational } from '@/libs/app';
import { Product } from './Product';
import { useMasterData } from '@/contexts/profile';
import { AnimatePresence, motion } from 'framer-motion';
import Sticker from '@/features/consults/chat/partials/sticker';
import { FaceIcon } from '@/components/icons/Face';
import { HandoverModel } from '@/models/handover';
import { RenderAuctionWin } from '@/features/consults/chat/partials/message/RenderAuctionWin';
import RateStar from '@/components/icons/RateStar';
import { useDisableBodyScroll } from '@/libs/hook';
import Rating from 'react-rating';
import { RenderRevokedMessage } from '@/features/consults/chat/partials/message/RenderRevokedMessage';
import RenderProductsMessage from '@/features/consults/chat/partials/message/RenderProductMessage';
export type ConversationPartialProps = {
  socket: any;
  ownerId: number;
  ownerName: string;
  conversationId: number;
  state: ChatReducerTypes;
  productId: number;
  dispatch: Dispatch<ChatActionType>;
  seenMessage: (value: number) => void;
  newMessage: Message | undefined;
};
type IRatingStaff = {
  id: number;
  number_rating: number;
  content: string;
  user_id: number;
  staff_id: number;
  object_id: number;
  object_name: string;
  object_type: string;
  created_at: string;
  updated_at: string;
};
const ConversationPartial = ({
  conversationId,
  ownerId,
  ownerName,
  state,
  dispatch,
  socket,
  productId,
  seenMessage,
  newMessage,
}: ConversationPartialProps) => {
  const { t } = useTranslation(['consult', 'order', 'layout']);
  const masterData: any = useMasterData();
  const [conversation, setConversation] = useState<Conversation>();
  const [product, setProduct] = useState<ProductTypes>({} as ProductTypes);
  const collectionSticker = masterData?.list_collection_sticker || [];
  const [showSticker, setShowSticker] = useState<boolean>(false);
  const [showRating, setShowRating] = useState(false);
  const [rate, setRate] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [ratingStaffData, setRatingStaffData] = useState<IRatingStaff>();

  const [ratingMessage, setRatingMessage] = useState<string>('');

  const messageEl = useRef<any>(null);
  const { mutate: mutateSeenConversation } = useSeenConversation();
  const { mutate: ratingStaff } = useRatingStaff({
    onSuccess: () => {
      setRatingMessage('');
      setRate(0);
      toast.success('Cảm ơn bạn đã đánh giá nhân viên của An Thư!');
      setLoading(false);
      setShowRating(false);
    },
    onError: ({ message }) => {
      setLoading(false);
      toast.error(message);
    },
  });

  const scrollToBottom = () => {
    if (messageEl && messageEl.current) {
      messageEl.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  };
  const { refetch: refetchProduct } = useFetchProductByChatQuery(productId, {
    enabled: false,
    onSuccess: ({ data }) => {
      setProduct(data);
    },
  });

  useEffect(() => {
    refetchProduct();
  }, [productId, refetchProduct]);
  useEffect(() => {
    scrollToBottom();
  }, [state.messageMapper]);
  const { refetch: refetchConversation } = useFetchConversationById(
    conversationId,
    {
      onSuccess: ({ data }) => {
        setConversation(data);
        refetch();
      },
    }
  );
  const { refetch, isLoading } = useFetchConversationMessagesQuery(
    conversationId,
    { limit: 100, offset: 0 },
    {
      enabled: false,
      onSuccess: ({ data }) => {
        const messages = data;
        const payload = (Array.isArray(messages) ? messages : []).reduce(
          (acc, item) => {
            acc = { ...acc, [item.id]: item };
            return acc;
          },
          {}
        );
        dispatch({ type: ChatReducerType.OnMessageMapperInit, payload });
      },
      onError: ({ message }) => {
        toast.error(message);
      },
    }
  );
  const handleSeenMessage = (id: number) => {
    seenMessage(id);
    mutateSeenConversation(id);
  };
  const { mutate, isLoading: loadImage } = useUploadImage({
    onSuccess: (data: ImageUploadType) => {
      const payload = {
        method: 'chat',
        data: {
          type: 'send_message',
          data: {
            content: '',
            images: [data.data.location],
            conversation_id: conversationId,
            type: MessageType.Text,
          },
        },
      };

      socket.send(JSON.stringify(payload));
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: ConversationPayloadDefault,
    resolver: yupResolver(
      yup.object({
        comment: yup.string().required(t('Tư vấn không được để trống')),
      })
    ),
  });

  const onCommentClick = handleSubmit(({ comment }) => {
    reset({ ...ConversationPayloadDefault });
    if (!comment.trim() || !socket) return;
    const payload = {
      method: 'chat',
      data: {
        type: 'send_message',
        data: {
          content: comment,
          conversation_id: conversationId,
          type: MessageType.Text,
        },
      },
    };
    return socket.send(JSON.stringify(payload));
  });
  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      onCommentClick();
    }
  };

  const handleChangeFile = (event: any) => {
    if (!event.target.files[0]) {
      return;
    }
    const formData = new FormData();
    formData.append('file', event.target.files[0], event.target.files[0].name);
    mutate(formData);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMessageReceived = (message: Message) => {
    if (message.conversation_id === conversationId) {
      const _message = {
        content: message.content || '',
        conversation_id: message.conversation_id,
        created_at: message.created_at,
        id: message.id,
        images: message.images,
        order_code: message.order_code || '',
        status: message.status,
        type: message.type || message.message_type || '',
        user_id: message.user_id,
        handover: message.handover,
      };
      return dispatch({
        type: ChatReducerType.OnChatSubmit,
        payload: { [message.id]: _message },
      });
    }
  };
  useEffect(() => {
    if (newMessage) onMessageReceived(newMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessage]);

  useEffect(() => {
    refetchConversation();
  }, [conversationId, refetchConversation]);
  let idMessUser: number = 0;
  let idMessAd: number = 0;
  let userStaffId: number = 0;

  for (let m of Object.values(state.messageMapper)) {
    if (m.user_id !== ownerId) {
      userStaffId = m.user_id;
    }
  }

  let messageData = Object.values(state.messageMapper).map((d) => {
    d.user_id === ownerId ? (idMessUser += 1) : (idMessAd += 1);
    return {
      ...d,
      idMessUser: idMessUser,
      idMessAd: idMessAd,
      images: d.images || [],
    };
  });
  const sendSticker = (s: string) => {
    setShowSticker(false);
    const payload = {
      method: 'chat',
      data: {
        type: 'send_message',
        data: {
          content: '',
          conversation_id: conversationId,
          type: MessageType.Sticker,
          images: [s],
        },
      },
    };
    socket.send(JSON.stringify(payload));
  };
  const getAdmin = (id: number | undefined) => {
    if (!conversation || !conversation.user_conversations?.length || !id)
      return;
    return conversation.user_conversations.find((u) => u.user_id === id);
  };
  const renderMessage = (
    userId: number,
    content: string,
    createdAt: string,
    idMessAd: number,
    handover: HandoverModel | undefined
  ) => {
    const staff =
      userId > 0 ? getAdmin(userId) : getAdmin(handover?.to_user_id);

    if (!content) return <></>;
    return (
      <div
        className={`tw-bg-white ${
          ownerId === userId
            ? 'chat-user custom-chat-user cu-rec'
            : 'custom-chat-admin border-white ca-rec !tw-bg-white tw-break-all'
        } tw-px-4 tw-py-2 tw-rounded-2xl tw-w-fit tw-relative tw-break-all`}
      >
        {staff && ownerId !== userId && (
          <div
            className="tw-text-secondary-30"
            style={{
              fontSize: '10px',
              lineHeight: '15px',
            }}
          >
            {`${staff?.user_name} - ${toPhoneNumberNational(
              staff?.user_phone + '',
              staff?.user_country,
              true
            )} `}
          </div>
        )}
        {ownerId === userId && (
          <div
            className="tw-text-white"
            style={{
              fontSize: '10px',
              lineHeight: '15px',
            }}
          >
            {ownerName}
          </div>
        )}
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div
          className={`tw-text-right ${
            ownerId === userId ? 'tw-text-white' : 'tw-text-secondary-50'
          }`}
          style={{
            fontSize: '11px',
          }}
        >
          {humanTimeDate(createdAt)}
        </div>
      </div>
    );
  };

  const staff = useMemo(() => {
    if (!conversation || !conversation.user_conversations) return;
    return conversation.user_conversations.find(
      (u) => u.user_id === conversation.supported_by
    );
  }, [conversation]);

  useDisableBodyScroll(showRating);

  const submitRating = useCallback(() => {
    setLoading(true);
    if (!staff?.user_id) return;
    const payload = {
      staff_id: staff?.user_id,
      object_id: product.id,
      object_name: product.product_name,
      object_type: 'PRODUCT',
      content: ratingMessage,
      number_rating: rate,
    };
    ratingStaff(payload);
  }, [product, rate, ratingMessage, ratingStaff, staff]);
  const handleCheckRating = useCallback(async () => {
    if (!staff) return toast.error('Không có tư vấn viên!');
    if (!product) return;
    try {
      const result = await fetchRatingChat({
        pId: product.id,
        staffId: staff?.user_id,
      });
      if (!result) return setShowRating(true);
      setRatingStaffData(result.data);
      return setShowRating(true);
    } catch (error) {
      setRatingStaffData(undefined);
      setShowRating(true);
    }
  }, [product, staff]);

  return (
    <>
      <div
        className="tw-flex tw-flex-col tw-gap-y-4 tw-col-span-1 sm:tw-col-span-2 tw-rounded-t-2xl tw-bg-muted-main sm:tw-order-last lg:tw-order-none tw-h-full tw-overflow-hidden tw-relative"
        style={{
          background: '#EDF0F5',
          border: '1px solid #DFE1E7',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
        }}
      >
        {!socket && (
          <div className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-z-20 tw-h-full tw-bg-gray-50 !tw-bg-opacity-30 tw-flex tw-justify-center tw-items-center">
            <LoadingDot32px />
          </div>
        )}
        <Product conversationId={conversationId} product={product} t={t} />
        <div className="tw-flex tw-flex-col tw-gap-y-4 tw-flex-1 tw-overflow-auto tw-p-4">
          {messageData.map((message) => {
            const staff =
              message.user_id > 0
                ? getAdmin(message.user_id)
                : getAdmin(message.handover?.to_user_id);

            if (message.is_revoked) {
              return (
                <div key={`message-${message.id}`}>
                  <div
                    className={`tw-flex ${
                      ownerId === message.user_id
                        ? 'tw-justify-end'
                        : 'tw-justify-start '
                    }`}
                  >
                    <RenderRevokedMessage
                      userId={message.user_id}
                      idMessAd={idMessAd}
                      ownerId={ownerId}
                      ownerName={ownerName}
                      sender={staff}
                      createdAt={message.created_at}
                      isPopup={true}
                      t={t}
                    />
                  </div>
                </div>
              );
            }

            return (
              <div key={`message-${message.id}`}>
                {message.type === MessageType.SellerAccepted && (
                  <div className="tw-py-3 tw-text-primary-main tw-text-xs tw-text-center tw-flex-wrap tw-flex tw-items-center tw-justify-center">
                    {'An Thư Diamond - '}
                    {staff?.user_name ? staff?.user_name : 'Admin'}
                    &nbsp;{t('bắt đầu tư vấn cho bạn')}
                  </div>
                )}
                {message.type === MessageType.OrderInfo && (
                  <RenderOrder orderCode={message.order_code} t={t} />
                )}
                {message.type === MessageType.AuctionWin && (
                  <RenderAuctionWin
                    userId={message.user_id}
                    ownerId={ownerId}
                    sender={staff}
                    createdAt={message.created_at}
                    content={message.content}
                    product={product}
                    idMessAd={idMessAd}
                    t={t}
                  />
                )}
                {message.type === MessageType.Video &&
                  message.images &&
                  message.images.length !== 0 && (
                    <video width="75%" height="240" controls>
                      <source src={message.images[0]} type="video/ogg" />
                    </video>
                  )}
                <div
                  className={`tw-flex ${
                    ownerId === message.user_id
                      ? 'tw-justify-end'
                      : 'tw-justify-start '
                  }`}
                >
                  {message.type === MessageType.Product && (
                    <RenderProductsMessage
                      msgId={message.id}
                      productCodes={message.images}
                      userId={message.user_id}
                      idMessAd={idMessAd}
                      ownerId={ownerId}
                      ownerName={ownerName}
                      sender={staff}
                      createdAt={message.created_at}
                      isPopup={true}
                      t={t}
                    />
                  )}
                  {(message.type === MessageType.Text ||
                    message.type === MessageType.Sticker) &&
                    message.images &&
                    message.images.length !== 0 && (
                      <RenderImage
                        userId={message.user_id}
                        ownerId={ownerId}
                        ownerName={ownerName}
                        idMessAd={idMessAd}
                        sender={staff}
                        image={message.images}
                        createdAt={message.created_at}
                        isPopup={true}
                      ></RenderImage>
                    )}
                  {message.type != MessageType.Handover &&
                  message.type != MessageType.OrderInfo &&
                  message.type != MessageType.SellerAccepted &&
                  message.type != MessageType.Product &&
                  message.images.length === 0
                    ? renderMessage(
                        message.user_id,
                        message.content,
                        message.created_at,
                        idMessAd,
                        message.handover
                      )
                    : ''}
                </div>
              </div>
            );
          })}
          <div className="" key="message-noproduct-end" ref={messageEl} />
        </div>
        <div className="tw-relative">
          <AnimatePresence>
            {showSticker && (
              <motion.div
                initial="stickerWrapperInitial"
                exit="stickerWrapperExit"
                animate="stickerWrapperAnimate"
                transition={{ duration: 0.3 }}
                variants={{
                  stickerWrapperInitial: {
                    opacity: 0,
                  },
                  stickerWrapperExit: {
                    opacity: 0,
                  },
                  stickerWrapperAnimate: {
                    opacity: 1,
                  },
                }}
              >
                <div
                  className="tw-absolute tw-bottom-full tw-left-1/2 tw--translate-x-2/4 tw-w-full tw-rounded-5 tw-drop-shadow-lg"
                  style={{
                    backgroundColor: '#F7F7FC',
                    width: '97%',
                  }}
                >
                  <div className="tw-relative tw-w-full">
                    <div className="tw-w-full tw-py-2">
                      {!!collectionSticker && (
                        <Sticker
                          collectionStickers={collectionSticker}
                          sendSticker={sendSticker}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="tw-flex tw-gap-x-2 tw-items-center tw-py-3 tw-px-5 tw-rounded-b-2xl">
            <div className="tw-flex tw-items-center tw-gap-1">
              {product && product.id && (
                <div className="tw-text-primary-main">
                  <Icon
                    onClick={handleCheckRating}
                    className="tw-w-8 tw-h-8 tw-cursor-pointer"
                    icon={<RateStar />}
                  />
                </div>
              )}
              <div className="tw-text-primary-main tw-flex tw-items-center tw-relative">
                <input
                  type="file"
                  className="tw-absolute tw-w-full tw-h-full tw-opacity-0"
                  onChange={handleChangeFile}
                />
                <Icon className="tw-w-8 tw-h-8" icon={<ImageUploadIcon />} />
              </div>
            </div>
            <div className="tw-flex-1">
              <TextField
                name="comment"
                control={control}
                variant={InputVariant.Outlined}
                error={errors.comment?.message}
                onKeyDown={keyPress}
                onFocus={() => handleSeenMessage(conversationId)}
                placeholder={t('Gửi câu hỏi tư vấn')}
                containerClassName="tw-bg-white tw-border-0 tw-rounded-full tw-flex-1 tw-px-4"
              />
            </div>

            <Button
              loading={isLoading}
              onClick={onCommentClick}
              className="tw-flex tw-justify-center tw-items-center !tw-rounded-full tw-bg-primary-main !tw-w-8 !tw-h-8 md:!tw-w-10 md:!tw-h-10"
            >
              <Icon className="tw-w-4 tw-h-4" icon={<SendIcon />} />
            </Button>
            {!!collectionSticker.length && (
              <Button
                loading={isLoading}
                onClick={() => setShowSticker(!showSticker)}
                className="tw-flex tw-justify-center tw-items-center !tw-rounded-full !tw-bg-transparent !tw-border-0 !tw-text-primary-main !tw-w-10 !tw-h-10"
              >
                <Icon className="tw-w-4 tw-h-4" icon={<FaceIcon />} />
              </Button>
            )}
          </div>
        </div>
      </div>
      {showRating && staff && (
        <>
          {ratingStaffData ? (
            <Modal
              className="!tw-p-0 tw-rounded-10 tw-overflow-hidden"
              onClose={() => setShowRating(false)}
            >
              <ModalHeader className="tw-bg-primary-main tw-py-2">
                <div className="tw-text-lg tw-text-center tw-font-semibold tw-text-white">
                  Đánh giá
                </div>
              </ModalHeader>
              <ModalBody className="tw-pb-8">
                <div className="tw-flex tw-justify-center">
                  <Rating
                    readonly
                    emptySymbol={
                      <Image
                        src="/star-empty.svg"
                        className="tw-w-8 tw-h-8 tw-mr-3"
                        alt="star-empty"
                      />
                    }
                    fullSymbol={
                      <Image
                        src="/star-fill.svg"
                        className="tw-w-8 tw-h-8 tw-mr-3"
                        alt="star-full"
                      />
                    }
                    placeholderRating={ratingStaffData.number_rating}
                    placeholderSymbol={
                      <Image
                        src="/star-fill.svg"
                        className="tw-w-8 tw-h-8 tw-mr-3"
                        alt="star-full"
                      />
                    }
                  />
                </div>
                <div className="tw-flex tw-flex-col tw-mt-3 tw-px-5">
                  <p className="tw-text-center tw-font-semibold">Nội dung</p>
                  <p className="tw-text-center tw-mt-2">
                    {ratingStaffData.content}
                  </p>
                </div>
              </ModalBody>
            </Modal>
          ) : (
            <Modal onClose={() => setShowRating(false)}>
              <ModalHeader>
                <div className="tw-text-lg tw-text-center tw-font-semibold">
                  Đánh giá tư vấn viên
                </div>
              </ModalHeader>
              <ModalBody>
                <div className="tw-flex tw-justify-center">
                  <Image
                    src={staff.user_avatar}
                    alt={staff.user_name}
                    className="tw-h-20 tw-w-20 tw-rounded-full tw-overflow-hidden"
                  />
                  <div className="tw-flex tw-flex-col tw-items-center tw-ml-3">
                    <p>Tư vấn viên</p>
                    <p className="tw-font-semibold">{staff.user_name}</p>
                    <Rating
                      emptySymbol={
                        <Image
                          src="/star-empty.svg"
                          className="tw-w-8 tw-h-8 tw-mr-1"
                          alt="star-empty"
                        />
                      }
                      fullSymbol={
                        <Image
                          src="/star-fill.svg"
                          className="tw-w-8 tw-h-8 tw-mr-1"
                          alt="star-full"
                        />
                      }
                      onChange={(rate) => setRate(rate)}
                      placeholderRating={rate}
                      placeholderSymbol={
                        <Image
                          src="/star-fill.svg"
                          className="tw-w-8 tw-h-8 tw-mr-1"
                          alt="star-full"
                        />
                      }
                    />
                  </div>
                </div>
                <div className="tw-flex tw-flex-col tw-mt-3">
                  <p className="tw-text-center">Nội dung đánh giá:</p>
                  <textarea
                    rows={3}
                    className="tw-w-full tw-border tw-border-secondary-70/30 tw-mt-1 tw-p-2 focus-visible:tw-outline-none"
                    onChange={(e) => setRatingMessage(e.target.value)}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="tw-flex tw-justify-end">
                  <Button
                    className="tw-border-0 tw-w-max tw-px-6 hover:tw-bg-primary-main/10 tw-mr-2"
                    variant={ButtonVariant.Outlined}
                    onClick={() => setShowRating(false)}
                  >
                    Hủy
                  </Button>
                  <Button
                    className="tw-w-max tw-px-6 hover:tw-bg-primary-main/90"
                    variant={ButtonVariant.Standard}
                    onClick={submitRating}
                    loading={loading}
                  >
                    Đánh giá
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default ConversationPartial;
