import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  KeyActive,
  NotificationSocketType,
  PagePath,
  PlatForm,
  SocketChanel,
} from '@/layouts/shared';
import { useTranslation } from 'next-i18next';
import {
  HomeOutlineIcon,
  HomeIconActive,
  Icon,
  Image,
  Link,
  BellOutlineIcon,
  UserOutlineIcon,
  Button,
  SearchIcon,
  InputVariant,
  Input,
} from '@/components';
import { useCategories, useMasterData, useProfile } from '@/contexts/profile';
import { useFetchTotalByTab } from '@/features/notifications/list/api';
import { getAuth } from '@/libs/cookies';
import { MasterDataModel } from '@/models';
import { MenuItem } from './MenuItem';
import { Category } from '@/features/products/list/const';
import { Controller, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useDimensions } from './HeaderComponents/use-demension';
import { AnimatePresence, motion } from 'framer-motion';
import { MenuToggle } from './HeaderComponents/MenuToggle';
import { Navigation } from './HeaderComponents/Navigation';
import { useDisableBodyScroll } from '@/libs/hook';

type HeaderPartialProps = {
  isAuth: boolean;
  keyActive: string;
  platform: string;
};
export type IMenu = {
  path: string;
  title: string;
  target: string;
  key: string[];
  showKey?: string;
  children?: { name: string; slug: string }[] | null;
  categoryHeader?: {
    code: string;
    image: string;
  };
};

const HeaderPartial = ({ isAuth, keyActive, platform }: HeaderPartialProps) => {
  const profile: any = useProfile();
  const router = useRouter();
  const categories: Category[] | null = useCategories();
  const masterData: MasterDataModel = useMasterData();
  const [totalNotification, setTotalNotification] = useState<number>(0);
  const [waitingToReconnect, setWaitingToReconnect] = useState<boolean | null>(
    null
  );
  const [isOpen, setOpen] = useState<boolean>(false);
  const clientRef = useRef<any>(null);
  const timeOut = useRef<any>(null);
  const retryRef = useRef<any>(null);
  const containerRef = useRef(null);

  const { theme } = masterData ?? {};
  const { bg_navigation_menu_url, logo_url } = theme ?? {};

  const { control, handleSubmit, reset } = useForm<any>({
    defaultValues: {
      keyword: '',
    },
  });

  useEffect(() => {
    if (!router?.query.keyword) {
      reset();
    }
  }, [reset, router?.query]);
  useEffect(() => {
    // Function to check if the click target is outside the container
    const handleClickOutside = (event: any) => {
      if (
        containerRef.current &&
        !(containerRef.current as any).contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const { t } = useTranslation('layout');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { height } = containerRef ? useDimensions(containerRef) : { height: 0 };

  const ping = (ws: any) => {
    let socketPayload = { method: 'ping' };
    ws.send(JSON.stringify(socketPayload));
  };
  let items: IMenu[] = [
    {
      path: '',
      title: t('header.product'),
      target: '',
      showKey: 'category',
      key: [KeyActive.Product, KeyActive.ProductDetail],
      children: categories
        ? (categories as Category[]).map((c) => {
            return {
              name: c.product_category_name,
              slug: c.code,
            };
          })
        : [],
      categoryHeader: masterData?.category_image_header,
    },
    {
      path: PagePath.LiveVideosPage,
      title: t('header.live-stream'),
      target: '',
      key: [KeyActive.LiveVideo],
    },
    {
      path: PagePath.ReelPage,
      title: t('header.reel'),
      target: '',
      key: [KeyActive.Reels],
    },
    {
      path: '/blog/tin-tuc',
      title: 'Khuyến Mãi',
      target: '',
      showKey: 'blog',
      key: [KeyActive.News],
    },
  ];
  const { refetch: refetchTotalByTab } = useFetchTotalByTab({
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess({ data }) {
      const total = Object.values(data).reduce((a, b) => a + b, 0);
      setTotalNotification(total);
    },
  });
  useEffect(() => {
    const { token } = getAuth();
    if (!isAuth) return;
    if (waitingToReconnect) {
      return;
    }
    if (isAuth && !clientRef.current) {
      const ws = new WebSocket(
        `${
          process.env.WEBSOCKET_URL || 'wss://api-dev.anthu.tech/ws/realtime'
        }?token=${token}`
      );
      clientRef.current = ws;
      ws.onopen = () => {
        let socketPayload = {
          method: 'subscribe',
          type: 'unicast',
          channels: [{ id: SocketChanel.AtdNotification }],
        };
        ws.send(JSON.stringify(socketPayload));
        timeOut.current = setInterval(() => {
          ping(ws);
        }, 15000);
      };
      ws.onmessage = (evt: any) => {
        const m = JSON.parse(evt.data);
        if (
          [
            NotificationSocketType.CreateReplyComment,
            NotificationSocketType.ReadAllNotification,
            NotificationSocketType.ReadNotification,
          ].includes(m.method)
        )
          refetchTotalByTab();
      };
      ws.onclose = () => {
        if (clientRef.current) {
          console.log('ws closed by server');
        } else {
          console.log('ws closed by app component unmount');
          return;
        }
        console.log('ws closed');
        setWaitingToReconnect(true);
        retryRef.current = setTimeout(() => setWaitingToReconnect(null), 5000);
      };
      ws.onerror = (evt: any) => {
        setWaitingToReconnect(true);
        retryRef.current = setTimeout(() => setWaitingToReconnect(null), 5000);
      };
      return () => {
        ws.close();
        clientRef.current = null;
        clearInterval(timeOut.current);
        clearTimeout(retryRef.current);
        timeOut.current = null;
        retryRef.current = null;
      };
    }
  }, [isAuth, refetchTotalByTab, waitingToReconnect]);

  useEffect(() => {
    if (!isAuth) return;
    refetchTotalByTab();
  }, [isAuth, refetchTotalByTab]);
  useEffect(() => {
    const header = document.getElementById('no-sticky');
    const s = document.getElementById('is-sticky');
    const sticky = header?.offsetHeight || 0;
    const scrollCallBack = () => {
      if (window.pageYOffset > sticky) {
        s?.classList.add('sticky');
      } else {
        s?.classList.remove('sticky');
      }
    };
    window.addEventListener('scroll', scrollCallBack);
    return () => window.removeEventListener('scroll', scrollCallBack);
  });
  const onSubmit = handleSubmit((data) => {
    const keyword = data.keyword;
    if (!keyword) return;
    router.push({
      pathname: PagePath.CategoryPage,
      query: { id: 'all', keyword },
    });
  });
  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };
  useDisableBodyScroll(isOpen);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <>
      <div
        className={`tw-bg-primary-main/[.08] md:tw-hidden ${
          keyActive === 'product-detail' ||
          keyActive === 'guid-measuring' ||
          platform === PlatForm.Mobile
            ? 'tw-hidden'
            : 'tw-block'
        }`}
      >
        <div className="container tw-mx-auto tw-py-2 tw-flex tw-items-center">
          <Link url={PagePath.Shops}>
            <div className="tw-flex tw-items-center tw-mr-4 md:tw-mr-8">
              <Icon icon={<HomeIconActive />} className="tw-mr-2" />
              <span className="tw-text-secondary-70 tw-text-sm">
                Hệ thống chi nhánh An Thư
              </span>
            </div>
          </Link>
          <Link url={`tel:0333336789`}>
            <div className="tw-flex tw-items-center">
              <Image
                alt="call"
                src="/call.svg"
                objectFit="contain"
                className={`tw-w-6 tw-h-6 tw-mr-2`}
              />
              <span className="tw-text-secondary-70 tw-text-sm">
                033 333 6789
              </span>
            </div>
          </Link>
        </div>
      </div>

      <div className="my-header tw-hidden md:tw-block">
        <div className="tw-w-full tw-hidden md:tw-block">
          <div id="no-sticky">
            <div className="tw-bg-primary-main/[.08] ">
              <div className="container tw-mx-auto tw-py-3 tw-flex tw-items-center">
                <Link url={`tel:0333336789`}>
                  <div className="tw-flex tw-items-center">
                    <Image
                      alt="call"
                      src="/call.svg"
                      objectFit="contain"
                      className={`tw-w-6 tw-h-6 tw-mr-2`}
                    />
                    <span className="tw-text-secondary-70 tw-text-md">
                      033 333 6789
                    </span>
                  </div>
                </Link>
              </div>
            </div>
            <div
              className="tw-pt-4 md:tw-pt-6 tw-pb-4 md:tw-pb-6 tw-w-full tw-bg-white no-sticky tw-bg-cover tw-bg-top tw-bg-no-repeat"
              style={{
                backgroundImage: bg_navigation_menu_url
                  ? `url(${bg_navigation_menu_url})`
                  : 'unset',
                backgroundSize: 'cover',
              }}
            >
              <div className="container tw-mx-auto tw-flex">
                <div className="tw-w-0 md:tw-w-4/12 tw-flex tw-gap-10">
                  <Link
                    url={{ pathname: PagePath.SupportPage }}
                    className="tw-text-base tw-font-regular hover:!tw-underline tw-uppercase"
                  >
                    <span className="tw-text-secondary-70">Hỗ trợ</span>
                  </Link>
                  <Link
                    url={{
                      pathname: PagePath.BlogPage,
                      query: { id: 'kien-thuc-trang-suc' },
                    }}
                    className="tw-text-base tw-font-regular hover:!tw-underline tw-uppercase"
                  >
                    <span className="tw-text-secondary-70">Kiến thức</span>
                  </Link>
                </div>
                <div className="tw-w-8/12 md:tw-w-4/12 md:tw-flex md:tw-items-center tw-justify-start md:tw-justify-center">
                  <Link url="/">
                    <div>
                      <span className="tw-hidden">Logo</span>
                      {logo_url ? (
                        <Image
                          src={logo_url}
                          alt="logo"
                          className="tw-h-16 tw-w-48"
                          objectFit="contain"
                        />
                      ) : (
                        <object
                          className="tw-h-16 tw-pointer-events-none tw-cursor-pointer"
                          type="image/svg+xml"
                          data="/logo.svg"
                        />
                      )}
                    </div>
                  </Link>
                </div>
                <div className="tw-w-4/12 tw-flex tw-justify-end tw-gap-x-2">
                  <Link url={{ pathname: PagePath.Shops }}>
                    <Icon
                      className="tw-text-secondary-70"
                      icon={<HomeOutlineIcon />}
                    />
                  </Link>
                  {isAuth ? (
                    <>
                      <Link
                        url={PagePath.NotificationsPage}
                        className="tw-flex"
                        title="Thông báo"
                      >
                        <div className="tw-relative tw-w-6 tw-h-6">
                          {totalNotification > 0 && (
                            <span className="tw-w-2 tw-h-2 tw-text-white tw-bg-danger-main tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-xs tw-absolute tw-right-0 tw-top-0 tw-z-10"></span>
                          )}

                          <Icon icon={<BellOutlineIcon />} />
                        </div>
                      </Link>
                      <Link
                        url={PagePath.AccountProfilePage}
                        title={`${
                          profile && profile.avatar ? 'Cá nhân' : 'Tài khoản'
                        }`}
                      >
                        {profile && profile.avatar ? (
                          <div className="tw-flex tw-items-center tw-gap-x-2">
                            <div
                              className="tw-h-6 tw-w-6 tw-rounded-full tw-bg-center tw-bg-cover tw-bg-no-repeat"
                              style={{
                                backgroundImage: `url('${profile.avatar}')`,
                              }}
                            />
                            <span className="tw-font-light tw-text-secondary-70">
                              {profile.fullname}
                            </span>
                          </div>
                        ) : (
                          <div
                            className="tw-h-6 tw-w-6 tw-rounded-full tw-bg-center tw-bg-contain tw-bg-no-repeat tw-border tw-border-primary-main"
                            style={{
                              backgroundImage: `url(/logo.svg)`,
                            }}
                          ></div>
                        )}
                      </Link>
                    </>
                  ) : (
                    <Link
                      url={PagePath.LoginPage}
                      title="login"
                      className="tw-flex tw-gap-x-2 !tw-text-secondary-70"
                    >
                      <Icon icon={<UserOutlineIcon />} />
                      <span className="tw-font-light">Đăng nhập</span>
                    </Link>
                  )}
                </div>
              </div>
              <div className="tw-mt-4  tw-flex tw-text-sm md:tw-text-base tw-items-center tw-justify-center tw-overflow-x-auto md:tw-overflow-visible tw-h-9 tw-relative">
                {items.map((item, index) => {
                  const isActive = item.key.includes(keyActive);
                  return (
                    <MenuItem
                      key={'menu-' + index}
                      item={item}
                      isActive={isActive}
                    />
                  );
                })}
                <span className="tw-h-[30px] tw-bg-primary-main tw-w-[1px]"></span>
                <div className="tw-px-4 lg:tw-px-6">
                  <Controller
                    name="keyword"
                    control={control}
                    render={({ field }) => {
                      return (
                        <div className="tw-flex tw-h-9 tw-px-2 focus:tw-outline-none">
                          <Input
                            {...field}
                            onKeyDown={keyPress}
                            variant={InputVariant.Outlined}
                            containerClassName="tw-rounded-5 !tw-border-primary-main tw-bg-gray-50"
                            placeholder="Tìm kiếm sản phẩm"
                            startAdornment={
                              <Icon
                                className="tw-w-6 tw-h-6"
                                icon={<SearchIcon />}
                              />
                            }
                            className="tw-w-[240px] lg:tw-w-[300px]"
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            id="is-sticky"
            className=" tw-bg-white is-sticky tw-z-40 tw-top-0 tw-left-0 tw-w-full tw-bg-cover tw-bg-top tw-bg-no-repeat"
            // style={{
            //   backgroundImage: `url('/header.svg')`,
            // }}
          >
            <div className=" md:tw-py-5 tw-py-3  tw-mx-auto tw-border-b tw-border-primary-main">
              <div className="tw-relative">
                <div className="container">
                  <div className="tw-w-full tw-flex tw-items-center tw-justify-center">
                    <div className="tw-text-sm md:tw-text-base tw-items-center tw-justify-center tw-overflow-x-auto tw-hidden md:tw-flex tw-h-full">
                      {items.map((item, index) => {
                        const isActive = item.key.includes(keyActive);
                        return (
                          <MenuItem
                            key={'menu-' + index}
                            item={item}
                            isActive={isActive}
                          />
                        );
                      })}
                      <span className="tw-h-[30px] tw-bg-primary-main tw-w-[1px]"></span>
                      <div className="tw-px-4 lg:tw-px-6">
                        <Controller
                          name="keyword"
                          control={control}
                          render={({ field }) => {
                            return (
                              <div className="tw-flex tw-px-2 tw-h-9 focus:tw-outline-none">
                                <Input
                                  {...field}
                                  onKeyDown={keyPress}
                                  variant={InputVariant.Outlined}
                                  containerClassName="tw-rounded-5 !tw-border-primary-main tw-bg-gray-50"
                                  placeholder="Tìm kiếm sản phẩm"
                                  startAdornment={
                                    <Icon
                                      className="tw-w-6 tw-h-6"
                                      icon={<SearchIcon />}
                                    />
                                  }
                                  className="tw-w-[240px] lg:tw-w-[300px]"
                                />
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {router.asPath === '/' && (
        <div className="tw-block md:tw-hidden tw-w-full">
          <div className="tw-w-full tw-flex tw-justify-center tw-items-center tw-py-3">
            <motion.div
              initial={false}
              animate={isOpen ? 'open' : 'closed'}
              custom={height}
              ref={containerRef}
              className="tw-absolute tw-top-[60px] tw-left-0 tw--translate-y-1/3 tw-text-black tw-z-999 "
            >
              <MenuToggle toggle={() => setOpen((prev) => !prev)} />
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    initial={{ width: 0, opacity: 0 }}
                    animate={{ width: 300, opacity: 1 }}
                    transition={{ ease: 'easeInOut', duration: 0.5 }}
                    className="tw-w-max tw-h-full "
                  >
                    <Navigation
                      categoryHeader={masterData.category_image_header}
                      handleClick={() => setOpen(false)}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
            <Link url="/">
              <div>
                <span className="tw-hidden">Logo</span>
                {logo_url ? (
                  <Image
                    src={logo_url}
                    alt="logo"
                    className="tw-h-7 tw-w-24 tw-pointer-events-none tw-cursor-pointer"
                    objectFit="contain"
                  />
                ) : (
                  <object
                    className="tw-h-7 tw-pointer-events-none tw-cursor-pointer"
                    type="image/svg+xml"
                    data="/logo.svg"
                  />
                )}
              </div>
            </Link>
          </div>
          <div className="tw-pb-3">
            <Controller
              name="keyword"
              control={control}
              render={({ field }) => {
                return (
                  <div className="tw-flex tw-px-2 tw-h-9 focus:tw-outline-none">
                    <Input
                      {...field}
                      onKeyDown={keyPress}
                      variant={InputVariant.Outlined}
                      containerClassName="tw-rounded-5 !tw-border-primary-main tw-bg-gray-50 tw-w-full"
                      placeholder="Tìm kiếm sản phẩm"
                      startAdornment={
                        <Icon className="tw-w-6 tw-h-6" icon={<SearchIcon />} />
                      }
                      className="tw-w-full"
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default memo(HeaderPartial);
